<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-6">
                <h3>Holidays</h3>
            </div>

            <div class="col-md-6">
                <button class="btn btn-primary float-right" data-target="#modal-add" data-toggle="modal">Add Holiday</button>
            </div>

            
        </div>


        <div class="page-body">
            <table class="table table-borderless table-striped">
                <thead>
                    <th>Sl No.</th>
                    <th>Holiday Name</th>
                    <th>Date</th>
                    <th>Recurring?</th>
                    <th></th>
                </thead>

                <tbody>
                    <tr *ngFor="let holiday of data; let i=index">
                        <td>{{i+1}}</td>
                        <td>{{holiday.name}}</td>
                        <td>{{holiday.date}}</td>
                        <td>
                            <ng-container *ngIf="holiday.is_fixed == 1">Recurring</ng-container>
                            <ng-container *ngIf="holiday.is_fixed == 0">Not Recurring</ng-container>
                        </td>
                        <td>
                            <button class="btn btn-sm btn-outline-primary" data-target="#modal-edit" data-toggle="modal" (click)="selectHoliday(holiday)">Edit</button>
                            &nbsp;
                            <button class="btn btn-sm btn-outline-danger" (click)="deleteHoliday(holiday)">Delete</button>
                        </td>
                    </tr>
                </tbody>

            </table>
        </div>

    </div>
</div>

<!-- modals -->

<div class="modal fade" id="modal-add" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add New Holiday</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #fh="ngForm" (ngSubmit)="newHoliday(fh.value)" id="formNewHoliday" ngNativeValidate>
                <div class="form-group">
                    <label for="">Holiday Name</label>
                    <input class="form-control" type="text" ngModel name="name" autocomplete="off" required>
                </div>

                <div class="form-group">
                    <label for="">Date</label>
                    <input class="form-control" type="date" ngModel name="date" required>
                </div>

                <div class="form-group">
                    <p for="">Does this holiday repeat every year on the same date?</p>
                    <button type="button" role="button" class="btn btn-sm" [ngClass]="(nhIsFixed == 1) ? 'btn-primary': 'btn-outline-primary'" (click)="setRecurring(1)">Yes</button>
                    &nbsp;
                    <button type="button" role="button" class="btn btn-sm" [ngClass]="(nhIsFixed == 0) ? 'btn-primary': 'btn-outline-primary'" (click)="setRecurring(0)">No</button>
                </div>

                
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary btn-close-modal" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formNewHoliday" class="btn btn-action">Submit</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>

<div class="modal fade" id="modal-edit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit Holiday</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #feh="ngForm" (ngSubmit)="editHoliday(feh.value)" id="formEditHoliday" ngNativeValidate>
                <div class="form-group">
                    <label for="">Holiday Name</label>
                    <input class="form-control" type="text" [(ngModel)]="selectedHoliday.name" name="name" autocomplete="off" required>
                </div>

                <div class="form-group">
                    <label for="">Date</label>
                    <input class="form-control" type="date" [(ngModel)]="selectedHoliday.date_raw" name="date" required>
                </div>

                <div class="form-group">
                    <p for="">Does this holiday repeat every year on the same date?</p>
                    <button type="button" role="button" class="btn btn-sm" [ngClass]="(selectedHoliday.is_fixed == 1) ? 'btn-primary': 'btn-outline-primary'" (click)="editRecurring(1)">Yes</button>
                    &nbsp;
                    <button type="button" role="button" class="btn btn-sm" [ngClass]="(selectedHoliday.is_fixed == 0) ? 'btn-primary': 'btn-outline-primary'" (click)="editRecurring(0)">No</button>
                </div>

                
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary btn-close-modal" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formEditHoliday" class="btn btn-action">Submit</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>