import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-batch-attendance',
  templateUrl: './batch-attendance.component.html',
  styleUrls: ['./batch-attendance.component.scss']
})
export class BatchAttendanceComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  id:any = 0;
  batch:any = {};
  students:any = {list:[], count:0};
  limit:any = 30;
  offset:any = 0;
  search:any = "";
  loading:any = false;
  date:any = "";
  today:any = "";
  attendance:any = [];
  isHoliday:any = false;
  selectedStudent:any = {};
  uploading:any = false;
  uploadFile:any;
  uploadSize:any = "";
  attendanceMonth:any = "";
  attendanceYear:any = "";
  bufferValue:any = 30;
  
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.date = new Date();
    this.today = new Date();
    const previousMonthDate = new Date(this.today.getFullYear(), this.today.getMonth() - 1, 1);
    const month = previousMonthDate.getMonth() + 1;
    this.attendanceMonth = month < 10 ? '0' + month : month.toString();
    this.attendanceYear = previousMonthDate.getFullYear().toString();

    this.loadBatch();
    this.loadStudents();
    
    this.loadAttendance();
  }

  loadBatch(){
    this.dataService.getBatch(this.id).subscribe( (data: any )=> {
      if(data.status == 0){
        this.batch = data;
      }
      
    } );
  }

  loadStudents(){
    this.dataService.getStudents(this.limit, this.offset, this.search, this.id).subscribe( (data: any )=> {
      if(data.status == 0){
        this.students = data;
      }
    } );
  }

  paginate(dir){
    if(dir == 2) {
      this.offset += this.limit;
    } else {
      this.offset -= this.limit;
      if(this.offset < 0){
        this.offset = 0;
      }
    }

    this.loadStudents();

  }

  formattedDate(date){
    const year = date.getFullYear();
    // Month is zero-indexed, so we add 1 to get the actual month
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // Format the date as a string (optional)
    //const formattedDate = `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`; //d-m-Y
    const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`; //Y-m-d

    return formattedDate;
  }

  setCustomDate(date){
    this.date = new Date(date);
    this.loadAttendance();
  }

  forwardDate(){
    const nextDate = new Date(this.date);
    nextDate.setDate(nextDate.getDate() + 1);
    this.date = nextDate;
    this.loadAttendance();
  }

  backwardDate(){
    const previousDate = new Date(this.date);
    previousDate.setDate(previousDate.getDate() - 1);
    this.date = previousDate;
    this.loadAttendance();
  }

  markAttendance(studentId, fn_an, attendance){

    if (!this.attendance[studentId]) {
      this.attendance[studentId] = {}; // Create an empty object if it doesn't exist
    }

    let data = {
      student_id:studentId,
      attendance:attendance,
      date: this.formattedDate(this.date),
      fn_an: fn_an
    }
    this.attendance[studentId][fn_an] = attendance;
    this.loading = true;
    this.dataService.postStudentAttendance(data).subscribe( (res:any) => {
      this.loading = false;
      if(res.status == 0) {
        //
      } else {
        alert(res.msg);
      }

    });
  }

  loadAttendance(){
    this.attendance = [];
    this.dataService.getStudentsAttendance(this.id, this.formattedDate(this.date)).subscribe( (data: any )=> {
      if(data.status == 0){
        this.attendance = data.data;
        this.isHoliday = data.isHoliday;
      }
    } );
  }

  selectStudent(student){
    this.selectedStudent = student;
  }

  onFileSelect(e){
    this.uploadFile = e.target.files[0];
    

    let uploadSize = "";
    if(this.uploadFile.size > 1000 && this.uploadFile.size < 1000000 ){
      uploadSize = (this.uploadFile.size / 1000).toFixed(1) + " KB";

    } else if(this.uploadFile.size >= 1000000){
      uploadSize = (this.uploadFile.size / 1000000).toFixed(1) + " MB";
    }
     else {
      uploadSize = this.uploadFile.size + " Bytes";
    }

    this.uploadSize = uploadSize;
    

  }

  submitXML(data){
    const formData = new FormData();
    formData.append('file', this.uploadFile, this.uploadFile.name);
    formData.append("student_id", this.selectedStudent.id);
    formData.append("year", data.year);
    formData.append("month", data.month);
    formData.append("buffer", data.buffer);
    this.uploading = true;

    this.dataService.postStudentAttendanceXML(formData).subscribe( (res:any = {status:0, msg:""}) => {
      this.uploading = false;
      if(res.status == 0) {
        console.log(res);
        //this.loadData();
        //@ts-ignore
        $("#modal-xml").modal("hide");
        alert(res.msg);
        this.setCustomDate(res.date);
      } else {
        alert(res.msg);
      }
    
    });

  }

}
