import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.scss']
})
export class HolidaysComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data:any = [];
  nhIsFixed:any = 0;
  loading:any = false;
  selectedHoliday:any = {};

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.dataService.getHolidays().subscribe( (data: any )=> {
      if(data.status == 0){
        this.data = data.data;
        console.log("data", this.data);
      }
    } );
  }

  deleteHoliday(holiday){
    let cnf = confirm("Are you sure you want to delete " + holiday.name + "?");
    if(cnf){

      let data = {id:holiday.id}
      this.dataService.postDeleteHoliday(data).subscribe( (data: any )=> {
        if(data.status == 0){
          this.loadData();
        }
      } );
    }
  }


  newHoliday(data){
    data.is_fixed = this.nhIsFixed;
    this.loading = true;
    this.dataService.postNewHoliday(data).subscribe( (data: any )=> {
      if(data.status == 0){
        this.loadData();
        this.loading = false;

        //@ts-ignore
        $(".btn-close-modal").trigger("click");
      }
    } );
  }

  editHoliday(data){
    data.is_fixed = this.selectedHoliday.is_fixed;
    data.id = this.selectedHoliday.id;
    this.loading = true;
    this.dataService.postEditHoliday(data).subscribe( (data: any )=> {
      if(data.status == 0){
        this.loadData();
        this.loading = false;

        //@ts-ignore
        $(".btn-close-modal").trigger("click");
      }
    } );
  }

  

  setRecurring(val){
    this.nhIsFixed = val;
  }

  editRecurring(val){
    this.selectedHoliday.is_fixed = val;
  }

  selectHoliday(holiday){
    this.selectedHoliday = holiday;
  }

}
