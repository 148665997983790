<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-6">
                <h3>Attendance Register - {{batch.course}} &nbsp; <br>
                    <small>Batch: {{batch.start_year}}</small>  &nbsp;
                    <small>Current Year: {{batch.current_semester}}</small>
                </h3>

                <ng-container *ngIf="batch.user_role == 1">
                    <button  class="btn btn-sm btn-dark" data-target="#modal-xml" data-toggle="modal">Upload XML</button>
                </ng-container>
                
            </div>

            <div class="col-md-6 text-right">
                
                <span class="date-nav" (click)="backwardDate()">
                    &lt;
                </span>
                <!--<span class="selected-date" [ngClass]="(formattedDate(date) == formattedDate(today)) ? 'today' : ''">
                    {{formattedDate(date)}}
                </span>-->

                <input class="selected-date" [ngClass]="(formattedDate(date) == formattedDate(today)) ? 'today' : ''" type="date" value="{{formattedDate(date)}}" (change)="setCustomDate($event.target.value)"/>

                <span class="date-nav" (click)="forwardDate()" *ngIf="formattedDate(date) != formattedDate(today)">
                    &gt;
                </span>

                <span class="date-nav" *ngIf="formattedDate(date) == formattedDate(today)" style="visibility: hidden;">
                    
                </span>

            </div>
        </div>

        <div class="page-body">
            <h4>Students ({{students.count}})</h4>

            <hr>

            <table class="table table-borderless table-striped">
                <thead>
                    <td>ID</td>
                    <td>College ID</td>
                    <td>Name</td>
                    <td>Check-in</td>
                    <td>Check-out</td>
                    <td style="width: 20%;">Forenoon</td>
                    <td style="width: 25%;">Afternoon</td>
                    
                    
                </thead>

                <tbody>
                    <ng-container *ngIf="students.list.length > 0; else noData">
                        <tr *ngFor="let student of students.list">
                            <td>
                                <a href="javascript:void(0);" routerLink="/dashboard/student/{{student.id}}"> {{student.student_id}} </a>
                            </td>
                            <td>
                                <a href="javascript:void(0);" routerLink="/dashboard/student/{{student.id}}"> {{student.college_id}} </a>
                            </td>
                            <td>
                                <a href="javascript:void(0);" routerLink="/dashboard/student/{{student.id}}"> {{student.name}} </a>
                            </td>
                            <td>
                                <ng-container *ngIf="attendance[student.id]">
                                    {{attendance[student.id].ts1}}
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngIf="attendance[student.id]">
                                    {{attendance[student.id].ts2}}
                                </ng-container>
                            </td>
                            <ng-container *ngIf="!isHoliday">
                                <ng-container *ngIf="batch.user_role != 2">
                                    <td>
                                        <span class="badge badge-success" *ngIf="attendance[student.id] && attendance[student.id][1] == 1">PRESENT</span>
                                        <span class="badge badge-danger" *ngIf="attendance[student.id] && attendance[student.id][1] == 2">ABSENT</span>
                                        <span class="badge badge-secondary" *ngIf="!attendance[student.id] || (attendance[student.id][1] != 1 && attendance[student.id][1] != 2)">Not Marked</span>

                                        <span></span>

                                    </td>
                                    <td>
                                        <span class="badge badge-success" *ngIf="attendance[student.id] && attendance[student.id][2] == 1">PRESENT</span>
                                        <span class="badge badge-danger" *ngIf="attendance[student.id] && attendance[student.id][2] == 2">ABSENT</span>
                                        <span class="badge badge-secondary" *ngIf="!attendance[student.id] || (attendance[student.id][2] != 1 && attendance[student.id][2] != 2)">Not Marked</span>
                                    </td>
                                </ng-container>
                                <ng-container *ngIf="batch.user_role == 2">
                                    <td>
                                        <button class="btn btn-sm" 
                                            [ngClass]="(attendance[student.id] && attendance[student.id][1] == 1) ? 'btn-success' : 'btn-outline-dark' " 
                                            (click)="markAttendance(student.id, 1, 1)"
                                        >
                                            Present
                                       </button>
                                       &nbsp;
        
                                        <button class="btn btn-sm"
                                            [ngClass]="(attendance[student.id] && attendance[student.id][1] == 2) ? 'btn-danger' : 'btn-outline-dark' "
                                            (click)="markAttendance(student.id, 1, 2)">
                                            Absent
                                       </button>
        
                                    </td>
        
                                    <td>
                                        <button class="btn btn-sm" 
                                            [ngClass]="(attendance[student.id] && attendance[student.id][2] == 1) ? 'btn-success' : 'btn-outline-dark' " 
                                            (click)="markAttendance(student.id, 2, 1)"
                                        >
                                            Present
                                       </button>
                                       &nbsp;
        
                                        <button class="btn btn-sm"
                                            [ngClass]="(attendance[student.id] && attendance[student.id][2] == 2) ? 'btn-danger' : 'btn-outline-dark' "
                                            (click)="markAttendance(student.id, 2, 2)">
                                            Absent
                                       </button>
        
                                    </td>
                                </ng-container>
                                
                                
                            </ng-container>
                            <ng-container *ngIf="isHoliday">
                                <td>
                                    <span class="badge badge-warning">HOLIDAY</span>
                                </td>
                                <td>
                                    <span class="badge badge-warning">HOLIDAY</span>
                                </td>
                            </ng-container>
                            
                            
                        </tr>
                    </ng-container>
                    
                    
                    <ng-template #noData>
                        <tr>
                           <td colspan="4" class="text-center">
                            No students added!
                           </td> 
                        </tr>
                    </ng-template>
                </tbody>

            </table>
            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-6">
                  <img src="assets/img/icon-arrow-left-active.svg" class="btn btn-nav" *ngIf="offset > 0" (click)="paginate(1)" />
                </div>
                <div class="col-md-6 text-right">
                  <img src="assets/img/icon-arrow-right-active.svg" class="btn btn-nav" *ngIf="students.count > offset+limit" (click)="paginate(2)" />
                </div>
            </div>

        </div>

    </div>
</div>



<div class="modal fade" id="modal-xml" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Upload Attendance File (xml)</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #fxml="ngForm" (ngSubmit)="submitXML(fxml.value)" id="formXML" ngNativeValidate>
                
                <div class="form-group">
                    <label class="control-label">Select XML File</label>
                    <div class="col-12">
                      <input type="file" class="form-control" name="file" (change)="onFileSelect($event)" accept=".xml" required/>
                      
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label">Select Year</label>
                    <div class="col-12">
                        <select class="form-control" [(ngModel)]="attendanceYear" name="year" required>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>

                            
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label">Select Month</label>
                    <div class="col-12">
                        <select class="form-control" [(ngModel)]="attendanceMonth" name="month" required>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                            
                            
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label for="control-label">Buffer (minutes)</label>
                    <div class="col-12">
                        <input type="number" class="form-control" [(ngModel)]="bufferValue" name="buffer">
                    </div>
                </div>

                

                
                
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!uploading" form="formXML" class="btn btn-action">Upload</button>
          <span class="spinner" *ngIf="uploading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>