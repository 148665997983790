import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule }from'@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StudentsComponent } from './dashboard/students/students.component';
import { StudentProfileComponent } from './dashboard/student-profile/student-profile.component';
import { CoursesComponent } from './dashboard/courses/courses.component';
import { BatchComponent } from './dashboard/batch/batch.component';
import { ExaminationsComponent } from './dashboard/examinations/examinations.component';
import { ExaminationComponent } from './dashboard/examination/examination.component';
import { FacultiesComponent } from './dashboard/faculties/faculties.component';
import { BatchAttendanceComponent } from './dashboard/batch-attendance/batch-attendance.component';
import { IndustrialVisitsComponent } from './dashboard/industrial-visits/industrial-visits.component';
import { IndustrialVisitComponent } from './dashboard/industrial-visit/industrial-visit.component';
import { TimeTableComponent } from './dashboard/time-table/time-table.component';
import { SyllabusComponent } from './dashboard/syllabus/syllabus.component';
import { SubjectAttendanceComponent } from './dashboard/subject-attendance/subject-attendance.component';
import { UserProfileComponent } from './dashboard/user-profile/user-profile.component';
import { TimeTablesComponent } from './dashboard/time-tables/time-tables.component';
import { StaffDetailsComponent } from './dashboard/staff-details/staff-details.component';
import { StaffDetailsListComponent } from './dashboard/staff-details-list/staff-details-list.component';
import { AttendanceMainComponent } from './dashboard/attendance-main/attendance-main.component';
import { SyllabusMainComponent } from './dashboard/syllabus-main/syllabus-main.component';
import { FacultyAttendanceComponent } from './dashboard/faculty-attendance/faculty-attendance.component';
import { FeePaymentsComponent } from './dashboard/fee-payments/fee-payments.component';
import { FeeStructureComponent } from './dashboard/fee-structure/fee-structure.component';
import { FeeStructureMainComponent } from './dashboard/fee-structure-main/fee-structure-main.component';
import { StudentFormComponent } from './dashboard/student-form/student-form.component';
import { ExamMainComponent } from './dashboard/exam-main/exam-main.component';
import { ExamSemesterScoreComponent } from './dashboard/exam-semester-score/exam-semester-score.component';
import { ReportsComponent } from './dashboard/reports/reports.component';
import { HolidaysComponent } from './dashboard/holidays/holidays.component';

const routes: Routes = [
  {
    path: '', component:LoginComponent
  },
  {
    path: 'login', component:LoginComponent
  },
  {
    path: 'dashboard', component:DashboardComponent,
    children: [
      {
        path:'students', component: StudentsComponent
      },
      {
        path:'student/:id', component: StudentProfileComponent
      },
      {
        path:'courses', component: CoursesComponent
      },
      {
        path:'batch/:id', component: BatchComponent
      },
      {
        path:'batch-attendance/:id', component: BatchAttendanceComponent
      },
      {
        path:'exam-main', component: ExamMainComponent
      },
      {
        path:'examinations/:batchId', component: ExaminationsComponent
      },
      {
        path:'examination/:batchId/:subjectId', component: ExaminationComponent
      },
      {
        path:'exam-total-score/:batchId/:semesterId', component: ExamSemesterScoreComponent
      },
      {
        path:'faculties', component: FacultiesComponent
      },
      {
        path:'industrial-visits', component: IndustrialVisitsComponent
      },
      {
        path:'industrial-visit/:id', component: IndustrialVisitComponent
      },
      {
        path:'time-table/:batchId', component: TimeTableComponent
      },
      {
        path:'time-tables', component: TimeTablesComponent
      },
      {
        path:'attendance-main', component: AttendanceMainComponent
      },
      {
        path:'syllabus-main', component: SyllabusMainComponent
      },
      {
        path:'syllabus/:courseId', component: SyllabusComponent
      },
      {
        path:'subject-attendance/:slotId', component: SubjectAttendanceComponent
      },
      {
        path:'profile', component: UserProfileComponent
      },
      {
        path:'staff-details', component: StaffDetailsComponent
      },
      {
        path:'staff-details/:id', component: StaffDetailsComponent
      },
      {
        path:'staff-details-list', component: StaffDetailsListComponent
      },
      {
        path:'faculty-attendance', component: FacultyAttendanceComponent
      },
      {
        path:'fee-payments', component: FeePaymentsComponent
      },
      {
        path:'fee-structure', component: FeeStructureMainComponent
      },
      {
        path:'fee-structure/:id', component: FeeStructureComponent
      },
      {
        path:'new-student/:batchId', component: StudentFormComponent
      },
      {
        path:'edit-student/:studentId', component: StudentFormComponent
      },
      {
        path:'reports', component: ReportsComponent
      },
      {
        path:'holidays', component: HolidaysComponent
      },


      
    ]
  },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    StudentsComponent,
    StudentProfileComponent,
    CoursesComponent,
    BatchComponent,
    ExaminationsComponent,
    ExaminationComponent,
    FacultiesComponent,
    BatchAttendanceComponent,
    IndustrialVisitsComponent,
    IndustrialVisitComponent,
    TimeTableComponent,
    SyllabusComponent,
    SubjectAttendanceComponent,
    UserProfileComponent,
    TimeTablesComponent,
    StaffDetailsComponent,
    StaffDetailsListComponent,
    AttendanceMainComponent,
    SyllabusMainComponent,
    FacultyAttendanceComponent,
    FeePaymentsComponent,
    FeeStructureComponent,
    FeeStructureMainComponent,
    StudentFormComponent,
    ExamMainComponent,
    ExamSemesterScoreComponent,
    ReportsComponent,
    HolidaysComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  exports: [RouterModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
