<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-8">
                <h3>Students ({{data.count}})</h3>
            </div>
            <div class="col-md-4 position-relative">
                <form #fsearch="ngForm" (ngSubmit)="searchData(fsearch.value)" id="formSearch" ngNativeValidate>
                    <input type="text" class="form-control" placeholder="Search by student name or ID" [(ngModel)]="search" name="search"/>
                    <span *ngIf="search != ''" class="btn-clear-search" (click)="clearSearch()"> &times; </span>
                </form>
            </div>
        </div>

        <div class="page-body">
            <table class="table table-borderless table-striped">
                <thead>
                    <td>ID</td>
                    <td>College ID</td>
                    <td>Name</td>
                    <td>Phone</td>
                    <td>Batch</td>
                    <td>Current Year</td>
                    <td></td>
                </thead>

                <tbody>
                    <ng-container *ngIf="data.list.length > 0; else noData">
                        <tr *ngFor="let student of data.list">
                            <td>{{student.student_id}}</td>
                            <td>{{student.college_id}}</td>
                            <td>{{student.name}}</td>
                            <td>{{student.phone}}</td>
                            <td>{{student.batch.name}}</td>
                            <td>{{student.batch.semester}}</td>
                            <td>
                                <button class="btn btn-sm btn-action" routerLink="/dashboard/student/{{student.id}}">View profile</button>
                            </td>
                        </tr>
                    </ng-container>
                    
                    
                    <ng-template #noData>
                        <tr colspan="6" class="text-center">
                            No students added!
                        </tr>
                    </ng-template>
                </tbody>

            </table>
            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-6">
                  <img src="assets/img/icon-arrow-left-active.svg" class="btn btn-nav" *ngIf="offset > 0" (click)="paginate(1)" />
                </div>
                <div class="col-md-6 text-right">
                  <img src="assets/img/icon-arrow-right-active.svg" class="btn btn-nav" *ngIf="data.count > offset+limit" (click)="paginate(2)" />
                </div>
            </div>
        </div>

    </div>
</div>